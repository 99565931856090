



























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import * as rules from "@/helpers/InputRule";
import { toCurrency } from "@/helpers/Formatter";

@Component
export default class InputCurrency extends Vue {
    @Prop() form!: any;
    @Prop() data!: any;

    public forceUpdateCount: number = 0;

    public inputType: string = "number";
    public lastValue: any = null;
    public errorMessage: string = "";

    get rules() {
        return rules;
    }

    toCurrencyString(value: any) {
        const convertedValue = value.toLocaleString().replace(/,/g, ".");

        return convertedValue;
    }

    @Emit("input:focus")
    setFocus(input: any) {
        this.inputType = "number";
        this.data[this.form.value] = this.lastValue;

        return input;
    }

    onBlur() {
        this.inputType = "text";
        this.lastValue = this.data[this.form.value];

        const convertedValue =
            this.data[this.form.value] == ""
                ? ""
                : this.toCurrencyString(+this.data[this.form.value]);

        this.data[this.form.value] = convertedValue;
    }

    toCurrency(number: any) {
        return toCurrency(number);
    }

    checkMaxFund() {
        this.errorMessage = "";

        if (this.data[this.form.value] > this.form.max) {
            this.data[this.form.value] = this.form.max;
            this.lastValue = this.form.max;
            this.errorMessage =
                "Maksimal dana yang diberikan " +
                this.toCurrency(this.form.max);
        }
    }

    forceUpdate() {
        this.forceUpdateCount++;
        this.$emit("forceUpdate");
    }

    mounted() {
        setTimeout(() => {
            const hasValue = !!this.data[this.form.value];

            if (hasValue) {
                this.lastValue = this.data[this.form.value];
                this.data[this.form.value] = this.toCurrencyString(
                    this.data[this.form.value]
                );
            }
        }, 100);
    }
}
